<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col-12">
        <div :class="['card', { 'no-card': disableCard }]">
          <div :class="['card-body', { 'no-card': disableCard }]">
            <span class="egx-partner-color">
              <h1 class="">{{ header.title }}</h1>
            </span>
            <h2 class="">{{ header.subtitle }}</h2>
            <p>{{ header.description }}</p>
            <Step :content="currentStepContent" :step="step" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Step from './Step.vue';
import mockData from '../mockData';

export default {
  components: {
    Step
  },
  props: {
    step: Number
  },
  data() {
    return {
      header: mockData.header,
      stepContent: mockData.stepContent,
      disableCard: true
    };
  },
  computed: {
    currentStepContent() {
      return this.stepContent[this.step - 1];
    }
  }
}
</script>

<style scoped>
.no-card {
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}
</style>
