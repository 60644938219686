<template>
  <div :class="['card', { 'no-card': disableCard }]">
    <div :class="['card-body', { 'no-card': disableCard }]">
      <span class="egx-partner-color">
        <h2>{{ content.title }}</h2>
      </span>
      <h3 class="egx-partner-color">{{ content.subtitle }}</h3>
      <p>{{ content.description }}</p>
      <component :is="getComponent(step)" :content="content" @navigate="navigate2" />
      <div class="d-flex justify-content-end mt-4" v-if="showFooterButton">
        <button class="btn btn-egx mr-4" @click="navigate2">Save &amp; Return to My Applications Page</button>
        <button class="btn btn-egx" @click="navigate2">Save &amp; Next Step</button>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from './Contact.vue';
import DetailSection from './project/DetailSection.vue';

export default {
  components: {
    Contact,
    DetailSection
  },
  props: {
    content: Object,
    step: Number
  },
  data() {
    return {
      disableCard: false,
      showFooterButton: true,
      nextPageParams: {},
    };
  },
  methods: {
    navigate(url) {
      const urlParams = new URLSearchParams(window.location.search);
      const step = urlParams.get('step');
      const newStep = url.includes('next-step') ? parseInt(step) + 1 : parseInt(step) - 1;
      urlParams.set('step', newStep);
      window.location.search = urlParams.toString();
    },

    navigate2() {
      let urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('type');
      urlParams.delete('project_id');
      urlParams.delete('status');
      for (const [key, value] of Object.entries(this.nextPageParams)) {
        urlParams.set(`${key}`, value);
      }
      window.location.search = urlParams.toString();
    },

    getComponent(step) {
      switch (step) {
        case 1:
          this.nextPageParams = {
            step: 2,
            type: 'prq',
            project_id: 'HW-174G-BNMA4',
            status: 'Eligibility Review',
          }
          return 'Contact';
        case 2:
          this.nextPageParams = {
            step: 3,
            type: 'attachment',
            project_id: 'HW-174G-BNMA4',
            status: 'Eligibility Review',
          }
          this.showFooterButton = false;
          return 'DetailSection';
        case 3:
          this.nextPageParams = {
            step: 1,
          }
          this.showFooterButton = false;
          return 'DetailSection';
        case 4:
          return 'OtherComponent3';
        default:
          return 'Contact';
      }
    }
  }
}
</script>

<style scoped>
.no-card {
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 768px) {
  .mr-4, .ml-4, .mb-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
</style>
